import App from '../vue_components/list_counter.vue'

document.addEventListener('DOMContentLoaded', () => {

  $('.list-counter').each((idx,el)=>{
    const props = JSON.parse(el.getAttribute('data'))
    new global.the_vue({
      store: global.the_store,
      render: h => h(App, { props })
    }).$mount(el);
  })
})

