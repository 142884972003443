<template>
  <span v-show='count > 0' class="counter badge badge" :class="'counter-'+type" >
    {{ count }}
  </span>
</template>

<script>
export default {
  props: {
    aasm_state: String,
    type: String,
    list: String
  },
  computed: {
    count: function () {
      return this.$store.getters[ this.list + '/listCount' ]({
        aasm_state: this.aasm_state,
        type: this.type
      })
    }
  }
}

</script>

<style lang="scss" scoped>
  .badge-pill-state-icon {
    text-align: center;
    padding: 1px 2px 2px 2px;
    font-size: 13px;
    .fas {
      width: 1rem;
    }
  }
  .counter {
    font-size: 12px;
  }
  .counter-priority-high {
    background-color: #ff5;
    color: black;
  }
  .counter-priority-urgent {
    background-color: #66f;
    color: white;
  }
  .counter-rotten, .counter-join_rotten {
    background-color: #d44;
    color: white;
  }
</style>
